@import url("https://use.typekit.net/cyr8qpt.css");
@import "./src/containers/themes/generalTheme";


$fontFamily: 'Lato', sans-serif;


@import "src/assets/css/responsive";

.socialMedia {
  display: flex;
  //width: 10%;
  //height:calc(100vh - 223px);
  height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  @include makeAnimationTransform(scale(0.8));
  top: 0;

  &.socialMediaRTL{
    right: 0;
  }
  &.socialMediaLTR{
    left: 0;
  }

  z-index: 9997;

 @include responsiveProperty(height,auto,100%,100%,100%,100%,100%,100%,100%,100%);
 @include responsiveProperty(width,100%,8%,90px,90px,90px,90px,95px,100px,100px);

  @include for-phone-only{
    margin: 0 !important;
    position: relative;
    bottom: 0;
    padding: 0;
    border-radius: 0;
  }


  .socialMediaList {
    //@include responsiveProperty(width,100%,100%,100%,100%,85%,70%,75%,45%,30%);

    width: 100%;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;


      @include for-phone-only{
        padding: 30px 35px;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100vw;
      }

      li {
        @include makeTransition();
        width: 100%;
        margin: 25px 0;

        @include for-phone-only{
          width: 100%;
          display: flex;
          margin: 0;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          transform: scale(1.2) !important;
        }

        a {

          width: 100%;

          @include for-phone-only{
            width: 50%;
          }

          img {
            width: 100%;


          }
        }

      }
    }
  }
}
