@import url('https://fonts.googleapis.com/css?family=Exo+2:900');
@import 'src/containers/themes/generalTheme.scss';
@import 'src/assets/css/properties';


.corner-item{
  width: 200px;
  height: 200px;
  position: absolute;

  &.corner-bottom-right{
    right: -18%;
    bottom: -23%;

    &.rtl{
      @include makeAnimationTransform(rotateY(180deg))
    }
  }

  &.corner-bottom-left{
    left: -18%;
    bottom: -23%;
  }

  img{
    width: 100%;
    object-fit: contain;

  }
}
