*{
  user-select: none;
}

html{
  height: 100vh;
}
@import "./src/assets/css/fonts";
@import "./src/assets/css/animation";
@import "./src/assets/css/delayTimers";


@import "./src/containers/themes/generalTheme";
@import "./src/containers/themes/category_0_default_theme";
@import "./src/containers/themes/category_1_tazech_juice";
@import "./src/containers/themes/category_2_fire_ball";
@import "./src/containers/themes/category_4_nakoo";
@import "./src/containers/themes/category_3_karwanchi_water";
@import "./src/containers/themes/category_5_valencia";
@import "./src/containers/themes/category_6_cola";
@import "./src/containers/themes/category_7_fri_dai_friday";
@import "./src/containers/themes/category_8_rich";
@import "./src/containers/themes/category_9_malt";
@import "./src/containers/themes/category_10_zbeeb";
@import "./src/containers/themes/category_11_natural_water";
@import "./src/containers/themes/category_12_mina_water";
@import "./src/containers/themes/category_13_tonic";




#main-container{
  z-index: 1;
}

