@import url("https://use.typekit.net/cyr8qpt.css");
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700|Oswald:200,300,400,500,600,700|Roboto+Condensed:300,300i,400,400i,700,700i|Teko:300,400,500,600,700&display=swap');



/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('../fonts/myriad/MYRIADPRO-REGULAR.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('../fonts/myriad/MYRIADPRO-COND.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url('../fonts/myriad/MYRIADPRO-CONDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('../fonts/myriad/MyriadPro-Light.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('../fonts/myriad/MYRIADPRO-SEMIBOLD.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url('../fonts/myriad/MYRIADPRO-SEMIBOLDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url('../fonts/myriad/MYRIADPRO-BOLDCOND.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('../fonts/myriad/MYRIADPRO-BOLD.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url('../fonts/myriad/MYRIADPRO-BOLDIT.woff') format('woff');
}


@font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url('../fonts/myriad/MYRIADPRO-BOLDCONDIT.woff') format('woff');
}
