

@mixin allKeyframe ($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @-o-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}


@mixin animationBrowsers($property,$value){
  -webkit-animation-#{$property}: $value;
  -o-animation-#{$property}: $value;
  -moz-animation-#{$property}: $value;
  animation-#{$property}: $value;

}

@mixin animation-delay($delay){
  @include animationBrowsers(delay,$delay);
}
@mixin animation-duration($duration) {
  @include animationBrowsers(duration,$duration);
}
@mixin animation-name($name){
  @include animationBrowsers(name,$name);
}
@mixin animation-timing-function($timing-function){
  @include animationBrowsers(timing-function,$timing-function);
}
@mixin animation-iteration-count($iteration-count){
  @include animationBrowsers(iteration-count,$iteration-count);
}
@mixin animation-play-state($play-state){
  @include animationBrowsers(play-state,$play-state);
}
@mixin animation-fill-mode($fill-mode){
  @include animationBrowsers(fill-mode,$fill-mode);
}
@mixin animation-direction($direction){
  @include animationBrowsers(direction,$direction);
}





@mixin user-select($value){
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}
