$productInfoBg: radial-gradient(circle, rgb(236, 90, 17) 0%, rgb(236, 90, 17) 100%);
$pageBackground: radial-gradient(circle, rgb(236, 90, 17) 0%, rgb(255, 97, 17) 100%);
$footerBackground: radial-gradient(circle, rgb(236, 90, 17) 0%, rgb(236, 90, 17) 100%);
$solidColor: #ea580f;
$menuBarFontColor:#ffffff;
$menuBarDropdownFontColor:#fff;
$menuBarDropdownBackground:#ea580f;


$min-width-desktop-xxxLarge: 3072px;


@import "./src/containers/themes/generalTheme";
@include getTheme("tazech_juice",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);


#app.app-tazech_juice {


  #first-section {

    .section-1 {



    }
  }


  .slide-images {
    padding: 0;
    position: absolute;
    top: 10%;
    width: 100%;
    @include makeTransform(1.5,-100);

    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      width: 11% !important;
      z-index: 2222;
      position: relative;
      margin: 0 -2.5%;

      &:nth-child(1){
        z-index: 90 !important;
      }
      &:nth-child(2){
        z-index: 60 !important;
      }
      &:nth-child(3){
        z-index: 70 !important;
      }
      &:nth-child(4){
        z-index: 80 !important;
      }
      &:nth-child(5){
        z-index: 70 !important;
      }
      &:nth-child(6){
        z-index: 80 !important;
      }
      img {
        width: 100%;
        z-index: 0;
      }

    }
  }

}
