$productInfoBg: radial-gradient(circle, #510248 0%, #2A0248 100%);
$pageBackground: radial-gradient(circle, #510248 0%, #2A0248 100%);
$footerBackground: radial-gradient(circle, #2A0227 0%,  #250238 100%);
$solidColor:#250238;
$menuBarFontColor:#ffff;
$menuBarDropdownFontColor:#ffffff;
$menuBarDropdownBackground:#250238;


@import "./src/containers/themes/generalTheme";
@include getTheme("zbeeb",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);


#app.app-zbeeb {

  .slide-images {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    align-items: flex-end !important;

    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      width: 13%;
      z-index: 2222;
      margin: 0px -66px;

      img {
        width: 100%;
        z-index: 0;
      }

      &:last-child{
        width: 20%;
        margin: 3% 2%;

        &.browser_safari{
          width: 31%;
          margin: -12% 2%;
        }
      }

    }
  }
}
