@import url("https://use.typekit.net/cyr8qpt.css");

$fontFamily: 'Lato', sans-serif;

$myriad: 'Myriad Pro ';
$semibold: 'Semibold';
$bold: 'Bold';
$condensed: 'Condensed';
$regular: 'Regular';


@import "src/assets/css/responsive";

#footer.footer-contents-rtl {
  direction: rtl;
  .sections {
    text-align: right;


    .address-section {
      h1 {
        font-size: 22px;
        color: white;
        font-family: $myriad+$semibold;
        font-weight: 200;

        @include for-phone-only {
          margin: 25px 0;
        }

        small {
          font-size: 19px;
          font-family: $myriad+$regular;
          display: block;
          margin-left: 10px;
          direction: ltr;
        }
      }
    }
  }
}

#footer.footer-contents-ltr {
  direction: ltr;
  .sections {
    .address-section {
      h1 {
        font-size: 22px;
        color: white;
        font-family: $myriad+$semibold;
        font-weight: 200;

        @include for-phone-only {
          margin: 25px 0;
        }

        small {
          font-size: 19px;
          font-family: $myriad+$regular;
          display: block;
          margin-left: 10px;
        }
      }
    }
  }
}

#footer {
  //position: fixed;
  //bottom: 0;
  //background: rgb(223, 6, 31);
  //background: radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 3%, rgba(213, 5, 26, 1) 7%, rgba(180, 2, 12, 1) 26%, rgba(160, 0, 3, 1) 44%, rgba(154, 0, 0, 1) 85%, rgba(154, 0, 0, 1) 100%);
  width: 100%;
  //height: 367px;
  padding: 0;
  margin: 0;
  position: relative;
  bottom: 0;


  &.page_category{
    @include responsiveProperty(position, relative, relative, absolute, absolute, absolute, absolute, absolute, absolute, absolute);

    z-index: 9999;
  }


  .sections {
    padding: 35px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 6px 7px 16px -5px #000000;
    //

    @include for-xxx-large-desktop-up {
      padding: 35px 20%;
    }
    @include for-xx-large-desktop-up {
      padding: 35px 35px;
    }

    .address-section {
      h1 {

        @media only screen and (max-width: 992px) {
          width: 100%;
          display: block;
          text-align: center;
        }

        small {
        }
      }
    }

    .links-section {
      padding: 0 65px;
      display: flex;
      align-items: flex-start;
      border-right: 3px solid;
      border-left: 3px solid;
      //height: 180px;
      @include for-xx-large-desktop-up {
        margin: 35px 0;
        text-align: center;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          padding: 0;
          text-align: justify;
          margin: 6px 0;

          @include for-phone-only {
            text-align: center;
          }

          span, a {
            cursor: pointer;
            padding: 0;
            font-size: 22px;
            color: white;
            font-family: $myriad+$semibold;
            font-weight: 200;
            text-decoration: none;
          }
        }
      }
    }

    .logo-section {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo-container {
        padding: 0;
        width: 255px;

        img {
          padding: 0;
          width: 100%;
        }
      }
    }
  }

  .footer-line {
    //background: rgb(223, 6, 31);
    //background: linear-gradient(90deg, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 64%, rgba(187, 2, 15, 1) 85%, rgba(154, 0, 0, 1) 100%);
    padding: 21px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 15px;
      font-family: $myriad+$regular;

    }
  }

  .page-break {

  }
}
