$myriad: 'Myriad Pro ';
$semibold: 'Semibold';
$bold: 'Bold';
$condensed: 'Condensed';
$regular: 'Regular';

@import "src/assets/css/responsive";


#careers {
  padding: 0;
  min-height: 61vh;
  margin: 40px auto;

  @include for-phone-only{
    margin-top: 110px;
  }

  &.page-contents-rtl{
    text-align: right;
  }
  &.page-contents-ltr{
    text-align: left;
  }

  @include for-large-desktop-up {
    width: 1800px;
  }
  @include for-x-large-desktop-up {
    width: 2200px;
  }
  @include for-xx-large-desktop-up {
    width: 2800px;
  }


  .section {
    display: flex;
    justify-content: center;
    align-items: center;


    > div {
      width: 55%;

      @include for-x-small-desktop-up {
        width: 65%;
      }
      @include for-phone-only{
        width: 85%;
      }
    }

    .section-second-container {
      background: rgb(223, 6, 31);
      background: -moz-radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(234, 17, 46, 0.84) 3%, rgba(225, 8, 34, 0.96) 26%, rgba(223, 6, 31, 1) 85%);
      background: -webkit-radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(234, 17, 46, 0.84) 3%, rgba(225, 8, 34, 0.96) 26%, rgba(223, 6, 31, 1) 85%);
      background: radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(234, 17, 46, 0.84) 3%, rgba(225, 8, 34, 0.96) 26%, rgba(223, 6, 31, 1) 85%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df061f", endColorstr="#df061f", GradientType=1);

      //background: rgb(237,20,51);
      //background: -moz-radial-gradient(circle, rgba(237,20,51,1) 0%, rgba(234,17,46,1) 3%, rgba(225,8,34,1) 26%, rgba(223,6,31,1) 85%);
      //background: -webkit-radial-gradient(circle, rgba(237,20,51,1) 0%, rgba(234,17,46,1) 3%, rgba(225,8,34,1) 26%, rgba(223,6,31,1) 85%);
      //background: radial-gradient(circle, rgba(237,20,51,1) 0%, rgba(234,17,46,1) 3%, rgba(225,8,34,1) 26%, rgba(223,6,31,1) 85%);
      //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ed1433",endColorstr="#df061f",GradientType=1);
      box-shadow: 4px 6px 11px -5px #000000;
      padding: 30px 40px;

      form {
        .form-title {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $myriad+$regular;

          h1 {
            color: #fff;
          }
        }

        .form-inputs {
          padding: 0;

          input, textarea {
            padding: 14px 8px;
            display: flex;
            margin: 40px 0px;
            border: 0;
            border-bottom: 1px solid #ffffff45;
            background-color: transparent;
            color: white;
            width: 100%;
            border-radius: 0;
            font-family: $myriad+$regular;
          }


          input::placeholder, textarea::placeholder {
            color: #fff !important;
            opacity: 0.5;
          }

          input::placeholder:focus, textarea::placeholder:focus {
            color: #fff !important;
            opacity: 1;
          }

          input:focus, textarea:focus {
            color: #fff;
            outline: none;
            border-bottom: 1px solid #ffffff;
            box-shadow: none;
          }

          input:-webkit-autofill, input:-internal-autofill-selected {
            color: #fff;
            background-color: transparent;
          }

          .upload-cv-btn {
            padding: 0;

            button {
              border: none;
              background-color: white;
              padding: 8px 14px;
              box-shadow: 4px 6px 11px -5px #000000;
              font-family: $myriad+$regular;
            }
          }
        }

        .apply-btn {
          padding: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;

          button {
            border-radius: 4px;
            border: none;
            color: #ffffff;
            width: 155px;
            background: #df061f;
            background: radial-gradient(circle, #df061f 0%, #df061f 3%, #df061f 26%, #df051e 85%, #df041e 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df061f", endColorstr="#9a0000", GradientType=1);
            box-shadow: 4px 6px 11px -5px #000000;
            padding: 5px 0;

            span{
              font-family: $myriad+$bold;
            }
          }

        }
      }
    }


    .section-first-container {
      padding: 0;
      color: white;


      .career-title {
        padding: 0;

        h3 {
          font-size: 48px;
          color: #fff;
          font-family: $myriad+$bold;
        }
      }

      .jobs_list {
        height: 450px;

        @include for-phone-only{
          height: auto;
        }
        overflow: scroll;
        overflow-x: hidden;
        /* width */
        ::-webkit-scrollbar {
          width: 0;
        }

        .jobs_item {
          padding: 30px 0;

          &:not(:last-child) {
            border-bottom: 1px solid #fff;

          }

          .job-details {
            padding: 0;
            margin-bottom: 15px;

            h3 {
              padding: 0;
              color: #fff;
              font-family: $myriad+$semibold;
            }

            span {
              padding: 0;
              color: #fff;
              font-family: $myriad+$regular;

            }
          }
        }

      }
    }
  }
}
