$pageBackground: radial-gradient(circle, #10163D 0%, #000000 100%);
$productInfoBg: radial-gradient(circle, #E1B400 0%,  #E1B400 100%);
$footerBackground: radial-gradient(circle, #E1B400 0%,  #E1B400 100%);
$solidColor:#E1B400;
$menuBarFontColor:#E1B400;
$menuBarDropdownFontColor:#ffffff;
$menuBarDropdownBackground:#E1B400;


@import "./src/containers/themes/generalTheme";
@include getTheme("malt",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);


#app.app-malt {

  .slide-images {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display:flex;
    flex-direction:row;
    align-items: flex-end !important;
    //justify-content: flex-end !important;



    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      width: 20%;
      z-index: 2222;
      margin: 0 -5%;


      &:first-child{
        z-index: 55 !important;
      }

      &:nth-child(2){
        z-index: 77 !important;
      }
      &:nth-child(3){
        z-index: 66 !important;
      }

      &:last-child{
        z-index: 55 !important;
      }


      img {
        width: 100%;
        z-index: 0;
      }

    }
  }
}
