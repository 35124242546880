@import url("https://use.typekit.net/cyr8qpt.css");

$fontFamily: 'Lato', sans-serif;
$myriad: 'Myriad Pro ';
$semibold: 'Semibold';
$bold: 'Bold';
$condensed: 'Condensed';
$regular: 'Regular';

$min-width-desktop-xxxLarge: 3072px;
$min-width-desktop-xxLarge: 2560px;
$min-width-mobile-1: 858px;


@import "src/containers/themes/generalTheme";

#category-page.page-contents-ltr {


  #first-section {

    .section-1 {


      .slide-text {
        font-family: $myriad+$regular;
        font-size: 22px;
        line-height: 1.2 !important;
        padding: 0 22px 0 0px;
        text-align: justify;


        .category_description {
          span {
            font-family: $myriad+$regular;
            font-size: 18px;
          }
        }

        .category_products_btn {

          a {
            span {
              padding: 0 10px;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 19px;
            }
          }
        }
      }
    }


  }
}

#category-page.page-contents-rtl {


  #first-section {

    .section-1 {

      .slide-text {
        font-family: $myriad+$regular;
        font-size: 22px;
        line-height: 1.2 !important;
        padding: 0 0 0 22px;
        text-align: justify;

        .category_description {
          span {
            font-family: $myriad+$regular;
            font-size: 18px;
          }
        }

        .category_products_btn {

          a {
            span {
              padding: 0 10px;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 21px;
            }
          }
        }
      }
    }

  }
}

#category-page {
  //height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  height: 70%;
  //min-height: calc(100vh - 556px);
  position: relative;
  z-index: 999;

  @include safari-only{
    overflow-y: auto;

  }

  @include for-phone-only{
    height: auto;
    overflow-x: hidden;
  }

  @include responsiveProperty(width, unset, unset, unset, 1000px, 1200px, 1281px, 1800px, 2300px, 2300px);

  @include for-xx-large-desktop-up {
    transform: scale(1.5) translate(7%, 25%);
  }

  @mixin waterLineKeyframesX() {
    from,
    to {
      background-position-x: 640%;
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      background-position-x: 0%;
    }

    20%,
    40%,
    60%,
    80% {
      background-position-x: 640%;
    }
  }

  @-webkit-keyframes waterLineX {
    @include waterLineKeyframesX
  }
  @keyframes waterLineX {
    @include waterLineKeyframesX
  }

  @mixin waterLineKeyframesY() {
    from,
    to {
      background-position-y: 24%;
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      background-position-y: 10%;
    }

    20%,
    40%,
    60%,
    80% {
      background-position-y: 24%;
    }
  }

  @-webkit-keyframes waterLineY {
    @include waterLineKeyframesY
  }
  @keyframes waterLineY {
    @include waterLineKeyframesY
  }

  .karwnchi_water_line {
    position: fixed;
    top: 33%;

    @include for-tablet-landscape-up {
      top: 12%;
    }
    @include for-xx-small-desktop-up {
      top: 17%;
    }
    @include for-xx-large-desktop-up {
      top: -23%;
    }
    right: 0;
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-repeat: repeat-x;
    background-repeat-y: no-repeat;
    background-repeat-x: repeat;
    @include makeTransition(0.3s, ease-in-out);
    //background-position-y: 24%;

    &.karwnchi_water_line_loop {
      animation-timing-function: ease-in-out, ease-in-out;
      -moz-animation-timing-function: ease-in-out, ease-in-out;
      -webkit-animation-timing-function: ease-in-out, ease-in-out;
      -o-animation-timing-function: ease-in-out, ease-in-out;

      -webkit-animation-name: waterLineX, waterLineY;
      animation-name: waterLineX, waterLineY;

      -o-animation-iteration-count: infinite, infinite;
      -moz-animation-iteration-count: infinite, infinite;
      -webkit-animation-iteration-count: infinite, infinite;
      animation-iteration-count: infinite, infinite;

      -o-animation-duration: 550s, 25s;
      -moz-animation-duration: 550s, 25s;
      -webkit-animation-duration: 550s, 25s;
      animation-duration: 550s, 25s;
    }

    img {

      width: 100%;
      margin-top: -15%;
      //object-fit: contain;

    }
  }

  .row {
    margin: 0;
  }

  #first-section {
    padding: 45px 150px;
    height: 100%;
    //overflow: hidden;
    display: flex;
    align-items: flex-start;
    //flex-direction: column;

    @include for-phone-only {
      padding: 85px 20px;
      margin-top: 22%;
    }
    @include for-xx-small-desktop-up {
      padding: 45px;
    }
    @include for-x-small-desktop-up {
      padding: 45px 150px;
    }
    @include for-small-desktop-up {
      padding: 45px 50px;
    }
    @include for-large-desktop-up {
      padding: 45px 150px;
    }
    @include for-x-large-desktop-up {
      padding: 45px 150px;
    }
    @include for-xx-large-desktop-up {
      padding: 45px 150px;
    }

    > div {
      height: 100%;

      @include for-phone-only{
        height: auto;
      }
    }

    .image-background {

      padding: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 0;
      will-change: transform, box-shadow, z-index;

      img {
        padding: 0;
        width: 50%;
        margin-top: -7%;
        object-fit: contain;

        @include for-phone-only {
          width: 50%;
          @include makeAnimationTransform(translateY(-23%))
        }
        @include for-tablet-landscape-up {
          width: 50%;
          @include makeAnimationTransform(translateY(-33%))
        }
      }
    }

    .section-1 {
      padding: 0;
      display: flex;
      align-items: flex-start;

      .slide-text {
        font-family: $myriad+$regular;
        font-size: 22px;
        width: 66%;
        color: white;
        line-height: 1.2 !important;
        padding: 0 22px 0 0px;
        text-align: justify;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;


        &.product_info_open_RTL {
          @include responsiveProperty(transform, translateX(170px), translateX(170px), translateX(-120px) scale(0.6), translateX(50px), translateX(100px), translateX(15%), translateX(30%), translateX(0%), translateX(170px));
        }

        &.product_info_open_LTR {
          @include responsiveProperty(transform, translateX(-170px), translateX(-170px), translateX(-120px) scale(0.6), translateX(-50px), translateX(-100px), translateX(-15%), translateX(-30%), translateX(0%), translateX(-170px));
        }


        @include for-phone-only {
          width: 100%;
          padding: 0 !important;
          margin-bottom: 30px;
        }
        @include for-tablet-portrait-up {
          width: 100%;
        }
        @include for-tablet-landscape-up {
          width: 90%;
        }
        @include for-xx-small-desktop-up {
          width: 90%;
        }
        @include for-x-small-desktop-up {
          width: 90%;
        }
        @include for-small-desktop-up {
          width: 70%;
        }
        @include for-large-desktop-up {
          width: 70%;
        }
        @include for-x-large-desktop-up {
          width: 60%;
          transform: scale(1.3) translateX(18%);
        }

        .category_img {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            padding: 0;
            width: 57%;
          }
        }

        .category_description {
          padding: 0;
          margin: 27px 0;

          span {
            font-family: $myriad+$regular;
            padding: 0;
            font-size: 18px;
          }
        }

        .category_products_btn {
          padding: 0;
          margin-top: 29px;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            padding: 13px;
            border: 3px solid white;
            border-radius: 37px;
            color: white;

            &:hover {
              //color: white;
            }

            @include for-tablet-portrait-up {
              padding: 9px;
            }


            span {

              @include for-tablet-portrait-up {
                font-size: 16px !important;
              }
              @include for-tablet-landscape-up{
                font-size: 18px !important;
              }
              padding: 0 10px;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 21px;

            }
          }
        }

        text {
          font-size: 90px;
          transform: translateY(75px);
          fill: white;
        }

        //margin-top: 50px;
      }
    }


    .section-contents {
      padding: 0 0 63px 0;
      width: 100%;
      height: 100%;
      @include responsiveProperty("transform", scale(1) translateX(0), scale(1) translateX(0), scale(1) translateX(0), scale(1) translateX(0), scale(1) translateX(0), scale(1) translateX(0), scale(1) translateX(0), scale(1) translateX(0), translateX(-10%));


      @include for-phone-only{
        min-height: 700px;
      }


      .slide-images {
        padding: 0 0 100px 0;
        //position: absolute;
        //top: 0;
        //height: 60vh;
        //height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        //transform: translateY(-8%);
        pointer-events: none;

        @include responsiveProperty(
                        transform,
                        scale(2.5) translateY(30%), //for-phone-only
                        scale(4) translateY(32%), //for-tablet-portrait-up
                        scale(3) translateY(27%), //for-tablet-landscape-up
                        scale(2) translateY(11%), //for-xx-small-desktop-up
                        scale(2) translateY(13%), //for-x-small-desktop-up
                        scale(1.8) translateY(10%), //for-small-desktop-up
                        scale(1.3) translateY(0%), //for-large-desktop-up
                        scale(1.2) translateY(-10%), //for-x-large-desktop-up
                        scale(1.2) translateY(-10%), //for-xx-large-desktop-up
        );


        @include for-xxx-large-desktop-up {
          transform: scale(1.2) translateY(-10%);
        }


        div[class*='image-'] {
          padding: 0;
          display: inline-flex;
          z-index: 2222;
          position: relative;

          @include safari-only {
            @include responsiveProperty(height, 100px, 100px, 200px, 400px, 300px, 350px, 350px, 350px, 350px)
          }

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            z-index: 0;
          }

        }
      }
    }
  }
}
