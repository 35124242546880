$productInfoBg: radial-gradient(circle, #8cc63f 0%, #368724 100%);
$pageBackground: radial-gradient(circle, #8cc63f 0%, #368724 100%);
$footerBackground: radial-gradient(circle, #368724 0%, #2c9a1e 100%);
$solidColor:#368724;
$menuBarFontColor:#ffff;
$menuBarDropdownFontColor:#ffffff;
$menuBarDropdownBackground:#368724;

@import "./src/containers/themes/generalTheme";
@include getTheme("friday",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);

#app.app-friday {

  .slide-images {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    align-items: flex-end !important;

    &.browser_safari{
      margin-top: -20%;
    }

    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      width: 18%;

      position: relative;
      margin: 0 -5%;

      &:nth-child(1){
        z-index: 88 !important;
      }
      &:nth-child(2){
        z-index: 99 !important;
        margin-right: -3.5%;
      }
      &:nth-child(3){
        z-index: 99 !important;
        margin-left: -3.5%;
      }
      &:nth-child(4){
        z-index: 88 !important;
      }
      &:nth-child(5){
        z-index: 100 !important;
        width: 20%;
      }
      img {
        width: 100%;
        z-index: 0;
      }

    }
  }
}
