$productInfoBg: radial-gradient(circle, #ed1c24 0%, #000000 100%);
$pageBackground: radial-gradient(circle, #ed1c24 0%, #000000 100%);
$footerBackground: radial-gradient(circle, #a61319 0%, #000 100%);
$solidColor:#DF061F;
$menuBarFontColor:#DF061F;
$menuBarDropdownFontColor:#fff;
$menuBarDropdownBackground:#DF061F;


@import "./src/containers/themes/generalTheme";
@include getTheme("fire_ball",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);



#app.app-fire_ball {

  .row {
    margin: 0 !important;
  }

  .gif-background-rtl{
    img{

    }
  }
  .gif-background{
    padding: 0;
    display: flex;
    justify-content: flex-end;
    mix-blend-mode: screen;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    pointer-events: none;





    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform-origin: top;

      &.gif-background-rtl{
        @include for-phone-only{
          @include makeAnimationTransform(scale(2.5) translateX(-12%) translateY(1%))
        }
        @include for-tablet-landscape-up{
          @include makeAnimationTransform(scale(2.2) translateX(-12%) translateY(1%))
        }
        @include for-tablet-portrait-up{
          @include makeAnimationTransform(scale(2.2) translateX(0%) translateY(1%))
        }
        @include for-xx-small-desktop-up{
          @include makeAnimationTransform(scale(2) translateX(-12%) translateY(0))
        }
        @include for-x-small-desktop-up{
          @include makeAnimationTransform(scale(2.4) translateX(-12%) translateY(-6%))
        }
        @include for-small-desktop-up{
          @include makeAnimationTransform(scale(2.4) translateX(-12%) translateY(-4%))
        }
        @include for-large-desktop-up{
          @include makeAnimationTransform(scale(1.6) translateX(-12%) translateY(-4%))
        }
        @include for-x-large-desktop-up{
          @include makeAnimationTransform(scale(1.8) translateX(-12%) translateY(-4%))
        }
        @include for-xx-large-desktop-up{
          @include makeAnimationTransform(scale(2.5) translateX(-12%) translateY(0%))
        }
        @include for-xxx-large-desktop-up{
          @include makeAnimationTransform(scale(2.5) translateX(-12%) translateY(0%))
        }
      }
      @include for-phone-only{
        @include makeAnimationTransform(scale(2.5) translateX(-12%) translateY(1%))
      }
      @include for-tablet-landscape-up{
        @include makeAnimationTransform(scale(2.2) translateX(0%) translateY(1%))
      }
      @include for-tablet-portrait-up{
        @include makeAnimationTransform(scale(2.2) translateX(-12%) translateY(1%))
      }
      @include for-xx-small-desktop-up{
        @include makeAnimationTransform(scale(2) translateX(0) translateY(0))
      }
      @include for-x-small-desktop-up{
        @include makeAnimationTransform(scale(2.4) translateX(0) translateY(-6%))
      }
      @include for-small-desktop-up{
        @include makeAnimationTransform(scale(2.4) translateX(0) translateY(-4%))
      }
      @include for-large-desktop-up{
        @include makeAnimationTransform(scale(1.6) translateX(0) translateY(-4%))
      }
      @include for-x-large-desktop-up{
        @include makeAnimationTransform(scale(1.8) translateX(0) translateY(-4%))
      }
      @include for-xx-large-desktop-up{
        @include makeAnimationTransform(scale(2.5) translateX(0) translateY(0%))
      }
      @include for-xxx-large-desktop-up{
        @include makeAnimationTransform(scale(2.5) translateX(0) translateY(0%))
      }
    }
  }



  .slide-images {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;

    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      width: 30%;
      z-index: 2222;
      &:nth-child(2){
        width: 30%;
        z-index: 9999;
        margin: 0px -40px;

        img {
          width: 100%;
          z-index:9999;
        }
      }
      img {
        width: 100%;
        z-index: 0;
      }
    }
  }
}
