@import "src/assets/css/responsive";
@import "src/assets/css/properties";


#productInfo {
  position: absolute;
  height: 100%;
  display: flex;
  z-index: 9998;
  flex-direction: column;
  justify-content: center;
  top:0;
  align-items: center;
  @include for-phone-only{
    position: fixed;
    height: 100vh;
    z-index: 9999;
  }

  @include responsiveProperty(width,100%,300px,300px,300px,300px,300px,19%,15%,15%);
  //@include responsiveProperty(z-index,9999,9999,0,0,0,0,0,0,0);
  @include responsiveProperty(padding-top,100px,100px,0,0,0,0,0,0,0);




  .close-btn{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9999;
    color: white;
    @include user-select(none);

    span{
      font-size: 40px;
      color: white;
      font-family: fantasy;
    }
  }
  &.productInfoRTL{
    left: 0;
  }

  &.productInfoLTR{
    right: 0;
  }

  .product_details {

    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;


    .product-img{
      width:100%;
      height: 50%;
      padding: 9px 32px;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .product-title{
      padding: 0;
      height:125px;
      display: flex;
      justify-content: center;
      //text-transform: uppercase;

      &.normalSize{
        font-size: 40px;
      }
      &.small{
        font-size: 40px;
      }
      &.large{
        font-size: 32px;
      }
      &.xlarge{
        font-size: 33px;
      }

      h3{
        padding: 0;
        font-weight: bolder;
        //border-bottom: 7px solid;
        width: 65%;
        font-size: inherit;
        text-align: center;
      }
    }

    .product-ingredients{
      display: none;
      padding: 0;

      height: 40%;
      overflow: hidden;
      position: relative;
      ul{
        padding: 0;
        position: relative;
        overflow: auto;
        height: 100%;
        li{
          margin: 15px 0;
          padding: 0 31px;

          span{
            padding: 0;
            font-size: 21px;

            &:first-child{
              padding: 0;
            }
            &:last-child{
              padding: 0;
              float: right;
            }
          }
        }
      }
    }
  }
}
