$labelWidthXSmall: 40px;
$labelWidth: 46px;
$labelWidthLarge: 60px;
$labelWidthXLarge: 70px;
$min-width-mobile-1: 858px;


@import "src/assets/css/responsive";


#language-options {
  justify-content: flex-start;
  width: 100px;

  &:hover  .options-list-container{
    height: 126px;
    @include for-large-desktop-up{
      height: 250px;
    }
    @include for-phone-only{
      height: auto;
    }
  }

  @include for-phone-only{
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .language-label {
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    height:$labelWidth;
    width:$labelWidth;
    border-radius: 3px;
    cursor: pointer;


    @include for-xx-small-desktop-up{
      height:$labelWidthXSmall;
      width:$labelWidthXSmall;
    }
    @include for-large-desktop-up{
      height:$labelWidthLarge;
      width:$labelWidthLarge;
    }
    @include for-x-large-desktop-up{
      height:$labelWidthXLarge;
      width:$labelWidthXLarge;
    }
    @include for-xx-large-desktop-up{
      height:$labelWidthXLarge;
      width:$labelWidthXLarge;
    }
    @include for-phone-only{
      display: none;
    }
  }

  .options-list-container {
    position: absolute;
    width: $labelWidth;
    height:0;
    //top: 43px;
    z-index: 9999;

    border: 0 solid;
    border-radius: 3px;
    margin-top: 7px;
    overflow: hidden;
    transform-origin: top;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;


    @include for-xx-small-desktop-up{
      width:$labelWidthXSmall;
    }
    @include for-large-desktop-up{
      width:$labelWidthLarge;
    }
    @include for-x-large-desktop-up{
      width:$labelWidthXLarge;
    }
    @include for-xx-large-desktop-up{
      width:$labelWidthXLarge;
    }


    @include for-phone-only{
      display: flex;
      position: relative;
      width: 100%;
      height: auto;
      background-color: transparent !important;
      border-color: transparent !important;
      color: white !important;
    }

    ul{
      padding: 0;
      display: flex;
      flex-direction: column;
      width: $labelWidth;
      overflow: hidden;

      @include for-xx-small-desktop-up{
        width:$labelWidthXSmall;
      }
      @include for-large-desktop-up{
        width:$labelWidthLarge;
      }
      @include for-x-large-desktop-up{
        width:$labelWidthXLarge;
      }
      @include for-xx-large-desktop-up{
        width:$labelWidthXLarge;
      }
      @include for-phone-only{
        padding: 0;
        width: 100%;
        flex-direction: row;
        justify-content: center;
      }

      li{
        padding: 0;
        border-bottom: 1px solid;
        cursor: pointer;
        user-select: none;
        text-align: center;
        transition: all 0.5s ease;

        @include for-phone-only{
          background-color: transparent !important;
          height: auto;
          margin: 0 20px;
        }

        &:hover{
          padding: 0 10px;
        }
      }
      li.selected{
        background-color: transparent !important;
        padding: 0 10px;
      }
    }
  }
}
