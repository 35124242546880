$productInfoBg: linear-gradient(-161deg, rgba(242,242,242,1) 0%, rgba(179,179,179,1) 50%, rgba(179,179,179,1) 100%);
$pageBackground: linear-gradient(-161deg, rgba(242,242,242,1) 0%, rgba(179,179,179,1) 50%, rgba(179,179,179,1) 100%);
$footerBackground: linear-gradient(-161deg, rgba(242,242,242,1) 0%, rgba(179,179,179,1) 50%, rgba(179,179,179,1) 100%);
$solidColor:#FFDD00;
$menuBarFontColor:#FFDD00;
$menuBarDropdownFontColor:#ffffff;
$menuBarDropdownBackground:#FFDD00;


@import "./src/containers/themes/generalTheme";
@include getTheme("tonic",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg,#000);


#app.app-tonic {


  .slide-text{
    color:#000 !important;

    .category_products_btn a {
      border: 3px solid #000 !important;
      color: #000 !important;
    }
  }


  .slide-images {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display:flex;
    flex-direction:row;
    align-items: flex-end !important;



    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      z-index: 2222;
      width: 22% !important;
      margin: 0% -5%;

      &:nth-child(1){
        z-index: 2222 !important;
      }
      &:nth-child(2){
        z-index: 2222 !important;
      }
      &:nth-child(3){
        z-index: 9999 !important;
      }
      img {
        width: 100%;
        z-index: 0;
      }

    }
  }
}
