@import url('https://fonts.googleapis.com/css?family=Exo+2:900');
@import 'src/containers/themes/generalTheme.scss';
@import 'src/assets/css/properties';

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

$length:0;

.animationContainer {

  $length:attr(data-html);
  top: 0;
  right: 0;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  //background: inherit;
  z-index: 0;
  overflow: inherit;
  pointer-events: none;
}


.video-background {
  padding: 0;
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  background: inherit;
  transform: rotateY(180deg);

  video {
    padding: 0;
    opacity: 1;
    mix-blend-mode: screen;
    object-fit: cover;
  }
}


$blue: #ffffff50;
$lightBlue: #ffffff50;
$size: 50px;

/* customizable snowflake styling */
.animationItem:not(.animationItemImage) {
  display: block;
  background: #FFF;

  background: -moz-radial-gradient(center, ellipse cover, rgba($lightBlue, 0.2) 0%, rgba($blue, 1) 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba($lightBlue, 0.2)), color-stop(100%, rgba($blue, 1))); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, rgba($lightBlue, 0.2) 0%, rgba($blue, 1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover, rgba($lightBlue, 0.2) 0%, rgba($blue, 1) 100%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba($lightBlue, 0.2) 0%, rgba($blue, 1) 100%); /* IE10+ */
  background: radial-gradient(ellipse at center, rgba($lightBlue, 0.2) 0%, rgba($blue, 1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3369abdf', endColorstr='#54a9ed', GradientType=1); /* IE6-9 fallback on horizontal gradient */


  width: $size;
  height: $size;
  border-radius: $size;

  position: absolute;

}

.animationItemImage {
  display: block;
  width: $size;
  height: $size;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
  }
}



@include allKeyframe(fromTop){
  0% {
    top: -10%
  }
  100% {
    top: 100%
  }
}
@include allKeyframe(inMiddle) {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%);
  }
}
@include allKeyframe(itemShake) {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px)
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }
}



.animationItem {
  position: fixed;
  //bottom: -10%;
  z-index: 9999;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  //will-change: transform, box-shadow, z-index;





  &.overlay {
    mix-blend-mode: overlay;
  }
  &.soft {
    mix-blend-mode: soft-light;
  }


}

//animationItem itemsFromMiddle animationItemImage multiSizes
@mixin animationItem($i,$size) {

  $delay: randomNum(0,10)-0.5;


  .animationItem:nth-of-type(#{$i}) {

    left: percentage(randomNum(10,90) / 100);
    @include animation-delay(#{$delay+s, $delay+s});
    @include animation-duration(#{5s, 3s});
    @include animation-timing-function(#{linear, ease-in-out});
    @include animation-iteration-count(#{infinite, infinite});
    @include animation-play-state(#{running, running});



    &.itemsFromMiddle {
      @include animation-timing-function(ease-in-out);
      @include animation-name(fromMiddle#{$i});
      transform: scale(0);
    }
    &.itemsFromTop {
      @include animation-name(#{fromTop, itemShake});
      top: -10%;
    }
    &.itemsFromBottom {
      @include animation-timing-function(linear);
      @include animation-name(fromBottom#{$i});
      &.browser_safari{
        @include animation-duration(#{10s, 10s} !important);
      }
      bottom: -10%;
      transform: scale(1);
    }

    &.itemsInMiddle {
      $delay: randomNum(1,2)-0.5;
      @include animation-duration(#{3s});

      @include animation-timing-function(ease);
      @include animation-name(inMiddle#{$i});
      @include animation-delay(#{$delay+s});

      transform: scale(1);
    }

    &.bubbling {
      left: percentage(randomNum(40,60) / 100);
      @include animation-timing-function(linear);
      @include animation-name(bubbling#{$i});
      transform: scale(1);
    }

    &.swing {
      left: percentage(randomNum(10,90) / 100);
      top: -10%;
      @include animation-duration(#{3s, 8s} !important);
      @include animation-timing-function(#{linear,linear});
      @include animation-fill-mode(both);
      @include animation-name(#{swing#{$i},falling#{$i}});
      //@include makeAnimationTransform(scale(1));
    }


    &.onePiece {
      left: 0;
      width: 100%;
      height: 100%;
      padding: 80px;
    }

    &.multiSizes {
      width: $size;
      height: $size;
    }

  }
}

@mixin fromMiddleKeyframes($translateYIn,$translateYOut) {
  0% {
    //top: 50%;
    //width:0;
    filter: blur(0px);
    //transform: scale(0) translateX(0) rotateZ(randomNum(-360,360)+deg);
    @include makeAnimationTransform(scale(0) translateX(0) translateY($translateYIn) rotateZ(randomNum(-360,360)+deg));
    opacity: 1;
  }
  100% {
    //top: 50%;
    //width:300px;
    filter: blur(10px);
    //transform: scale(5) translateX(200%) rotateZ(randomNum(-360,360)+deg);
    @include makeAnimationTransform(scale(5) translateX(200%) translateY($translateYOut) rotateZ(randomNum(-360,360)+deg));
    opacity: 0;
  }
}
@mixin inMiddleKeyframes() {
  0% {
    @include makeAnimationTransform(translateY(-10px));
  }
  100% {
    @include makeAnimationTransform(translateY(10px));
  }
}
@mixin bubblingKeyframes() {
  0% {
    @include makeAnimationTransform(translateY(0) translateX(0));
    filter: blur(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include makeAnimationTransform(translateY(randomNum(-500,500)+px) translateX(randomNum(-500,500)+px));
    filter: blur(2px);
    opacity: 0;
  }
}
@mixin swingKeyframes() {
  $x: randomNum(0, 500);

  0% {
    @include makeAnimationTransform(rotateZ(0deg) rotateX(-360deg) rotateY(-360deg) translateX(0));
  }
  50% {
    @include makeAnimationTransform(rotateZ(0deg) rotateX(0deg) rotateY(0deg) translateX(50px));
  }
  100% {
    @include makeAnimationTransform(rotateZ(0deg) rotateX(-360deg) rotateY(-360deg) translateX(0));
  }
}
@mixin fallingKeyframes() {
  $y: randomNum(0, 100);

  0% {
    top: -10%;
    opacity: 1;
  }
  100% {
    top: percentage(randomNum(0,100) / 100);
    opacity: 0;
  }
}
@mixin fromBottomKeyframes($translateXTarget) {
  0% {
    opacity: 1;
    @include makeAnimationTransform(translateX(0px) translateY(0px))
  }
  50% {
    opacity: 0.5;
    @include makeAnimationTransform(translateX($translateXTarget) translateY(randomNum(0,-50)+vh))
  }
  100% {
    opacity: 0;
    @include makeAnimationTransform(translateX(0px) translateY(randomNum(-50,-100)+vh))
  }
}



@for $i from 0 through 1000 {

  $translateYIn: -($i*10)+px;
  $translateYOut: randomNum(0,100)+px;

  $translateXTarget: randomNum(0,100)+px;

  $size: randomNum(0,50)+px;


  @include allKeyframe(fromMiddle#{$i}){
    @include fromMiddleKeyframes($translateYIn, $translateYOut)
  }
  @include allKeyframe(inMiddle#{$i}){
    @include inMiddleKeyframes()
  }
  @include allKeyframe(bubbling#{$i}){
    @include bubblingKeyframes()
  }
  @include allKeyframe(swing#{$i}){
    @include swingKeyframes()
  }
  @include allKeyframe(falling#{$i}){
    @include fallingKeyframes()
  }
  @include allKeyframe(fromBottom#{$i}){
    @include fromBottomKeyframes($translateXTarget)
  }


  @include animationItem($i, $size);

}


