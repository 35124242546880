$productInfoBg: radial-gradient(circle, #9ed2f1 0%, #006cb7 100%);
$pageBackground: radial-gradient(circle, #9ed2f1 0%, #006cb7 100%);
$footerBackground: radial-gradient(circle, #85bee8 0%,  #006cb7 100%);
$solidColor:#006CB7;
$menuBarFontColor:#ffff;
$menuBarDropdownFontColor:#ffffff;
$menuBarDropdownBackground:#006CB7;


@import "./src/containers/themes/generalTheme";
@include getTheme("mina",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);


#app.app-mina {

  .slide-images {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display:flex;
    flex-direction:row;
    align-items: flex-end !important;


    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      z-index: 2222;
      width: 11% !important;
      margin: 0% -1%;

      &:nth-child(1){
        z-index: 2222;
      }
      &:nth-child(2){
        z-index: 9999 !important;
      }
      &:nth-child(3){
        z-index: 2222;
      }
      img {
        width: 100%;
        z-index: 0;
      }

    }
  }
}
