@import "src/assets/css/responsive";


.contents-list{
  padding: 0;
  //position: absolute;
  //top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  height: 100%;
  @include for-phone-only{
    height: auto;
  }


  &.product_info_open_RTL{
    @include responsiveProperty(transform,translateX(210px),translateX(210px),translateX(-120px) scale(0.6),translateX(140px),translateX(22%),translateX(85px),translateX(210px),translateX(210px),translateX(210px));
  }
  &.product_info_open_LTR{
    @include responsiveProperty(transform,translateX(-210px),translateX(-210px),translateX(-120px) scale(0.6),translateX(-140px),translateX(-22%),translateX(-85px),translateX(-210px),translateX(-210px),translateX(-210px));
  }

  ul{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    overflow: hidden;
    overflow-y: auto;
    @include for-phone-only{
      height: auto;
      overflow-y: hidden;
    }



    li{
      padding: 0;
      //width: 33%;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .item-image{
        padding: 0;
        margin-bottom: 20px;
        height: 204px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        transform-origin: bottom;
        //overflow: hidden;

        @include for-tablet-portrait-up{
          height: 130px;

        }
        @include for-tablet-landscape-up{
          height: 130px;

        }
        @include for-xx-small-desktop-up{
          height: 150px;

        }

        img{
          padding: 0;
          height: 100%;
          //-webkit-filter: drop-shadow(6px 15px 14px #222);
          //filter: drop-shadow(6px 15px 14px #222);

        }
      }
      .item-btn{
        border: 3px solid;
        border-radius: 37px;
        width: 90%;

        @include for-phone-only{
          width: 50%;
        }
        padding: 0 24px;
        height: 60px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include for-tablet-portrait-up{
          height: 50px;

        }
        @include for-tablet-landscape-up{
          height: 50px;

        }
        @include for-xx-small-desktop-up{
          height: 50px;

        }
        a{
          &:hover{
            //background-color: #5f0001;

          }
          span{
            padding: 0;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
            user-select: none;

            @include for-tablet-portrait-up{
              font-size: 11px;
            }
            @include for-tablet-landscape-up{
              font-size: 11px;
            }
            @include for-xx-small-desktop-up{
              font-size: 11px;
            }
            @include for-phone-only{
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
