$productInfoBg: radial-gradient(#ff5a58 0%, #fc151d 100%);
$pageBackground: radial-gradient(circle, rgba(252,21,29,1) 0%, rgba(252,21,29,1) 100%);
$footerBackground: radial-gradient(circle, rgba(249,0,19,1) 0%, rgba(249,0,19,1) 100%);
$solidColor:#F90013;
$menuBarFontColor:#ffff;
$menuBarDropdownFontColor:#F90013;
$menuBarDropdownBackground:#ffffff;

@import "./src/containers/themes/generalTheme";
@include getTheme("k_cola",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);



#app.app-k_cola {
  .slide-images {
    padding: 0;
    position: absolute;
    top: 10%;
    width: 100%;

    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      width: 21%;
      z-index: 2222;

      &:nth-child(2){
        //width: 21% !important;
        z-index: 9999 !important;
        margin: 0px -13%;
      }
      img {
        width: 100%;
        z-index: 0;
      }

    }
  }
}
