$myriad: 'Myriad Pro ';
$semibold: 'Semibold';
$bold: 'Bold';
$condensed: 'Condensed';
$regular: 'Regular';



#certificates {
  padding: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  &.page-contents-rtl {
    text-align: right;
  }
  .section {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      width: 50%;

      @media only screen and (max-width: 1200px) {
        width: 70%;
      }
      @media only screen and (max-width: 992px) {
        width: 80%;
      }
      @media only screen and (max-width: 320px) {
        width: 90%;
      }
    }

    .section-second-container {
      background: rgb(223, 6, 31);
      background: -moz-radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(234, 17, 46, 0.84) 3%, rgba(225, 8, 34, 0.96) 26%, rgba(223, 6, 31, 1) 85%);
      background: -webkit-radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(234, 17, 46, 0.84) 3%, rgba(225, 8, 34, 0.96) 26%, rgba(223, 6, 31, 1) 85%);
      background: radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(234, 17, 46, 0.84) 3%, rgba(225, 8, 34, 0.96) 26%, rgba(223, 6, 31, 1) 85%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df061f", endColorstr="#df061f", GradientType=1);

      //background: rgb(237,20,51);
      //background: -moz-radial-gradient(circle, rgba(237,20,51,1) 0%, rgba(234,17,46,1) 3%, rgba(225,8,34,1) 26%, rgba(223,6,31,1) 85%);
      //background: -webkit-radial-gradient(circle, rgba(237,20,51,1) 0%, rgba(234,17,46,1) 3%, rgba(225,8,34,1) 26%, rgba(223,6,31,1) 85%);
      //background: radial-gradient(circle, rgba(237,20,51,1) 0%, rgba(234,17,46,1) 3%, rgba(225,8,34,1) 26%, rgba(223,6,31,1) 85%);
      //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ed1433",endColorstr="#df061f",GradientType=1);
      box-shadow: 4px 6px 11px -5px #000000;
      padding: 30px 40px;

      .certificates-container{
        padding: 0;

        ul{
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          li{
            padding: 0;
            display: inline-flex;
            width: 29.33%;
            //background-color: white;
            height: 140px;
            margin: 0 5px;

            img{
              width: 100%;
            }
          }
        }


      }
      .form-title {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $myriad+$regular;

        h1 {
          color: #fff;
        }
      }
      form {


        .form-inputs {
          padding: 0;

          input ,textarea{
            padding: 0;
            display: flex;
            margin: 40px 0px;
            border: 0;
            border-bottom: 1px solid #ffffff45;
            background-color: transparent;
            color: white;
            width: 100%;
            border-radius: 0;
          }



          input::placeholder,textarea::placeholder {
            color: #fff !important;
            opacity: 0.5;
          }
          input::placeholder:focus,textarea::placeholder:focus {
            color: #fff !important;
            opacity: 1;
          }
          input:focus,textarea:focus{
            color: #fff;
            outline: none;
            border-bottom: 1px solid #ffffff;
            box-shadow: none;
          }
          input:-webkit-autofill,input:-internal-autofill-selected{
            color: #fff ;
            background-color: transparent;
          }

          .upload-cv-btn{
            padding: 0;

            button{
              border: none;
              background-color: white;
              padding: 8px 14px;
              box-shadow: 4px 6px 11px -5px #000000;
            }
          }
        }

        .apply-btn {
          padding: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;

          button {
            border-radius: 4px;
            border: none;
            color: #ffffff;
            width: 155px;
            background: #df061f;
            background: radial-gradient(circle, #df061f 0%, #df061f 3%, #bb020f 26%, #9a0000 85%, #9a0000 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df061f", endColorstr="#9a0000", GradientType=1);
            box-shadow: 4px 6px 11px -5px #000000;
            padding: 5px 0;
          }

        }
      }
    }


    .section-first-container {
      padding: 0;
      color: white;


      .career-title {
        padding: 0;

        h3 {
          font-size: 48px;
          color: #fff;
          font-family: $myriad+$bold;
        }
      }

      .job-details {
        padding: 0;
        margin-bottom: 15px;

        h3 {
          padding: 0;
          color: #fff;
          font-family: $myriad+$semibold;
        }

        span {
          padding: 0;
          font-size: 18px;
          color: #fff;
          font-family: $myriad+$regular;
        }
      }
    }


  }
}
