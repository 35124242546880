// str-replace() - Replace in a string
//
// @param {string}  $string    String that you want to replace
// @param {string}  $substr    String that is to be replaced by `$newsubstr`
// @param {string}  $newsubstr String that replaces `$substr`
// @param {number*} $all       Flag for replaceing all (1+) or not (0)
// @return {string}
/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}
$indexes: 0.5,1,1.5,2,2.5,3,3.5,4,4.5,5,5.5;

@each $index in $indexes {
    $classPrefix: str-replace(inspect($index),'.','-');
    .delay-#{$classPrefix}s{
        -webkit-animation-delay: #{$index}s;
        animation-delay: #{$index}s;
    }
}

