@import url("https://use.typekit.net/cyr8qpt.css");


$myriad: 'Myriad Pro ';
$semibold: 'Semibold';
$condensed: 'Condensed';
$regular: 'Regular';

$fontFamily: 'Lato', sans-serif;

@import "src/assets/css/responsive";


#home-page {
  //height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;

  &.page-contents-rtl {
    #second-section {
      .section-item {
        #production-line-section {
          text-align: right;
        }

        #certificates-section {
          text-align: right;
        }
      }
    }

    .react-multi-carousel-list {
      display: block;
    }
  }

  .row {
    margin: 50px 0;

    @include for-xx-large-desktop-up {
      margin: 130px 0;
    }
  }

  .image-background {
    padding: 0;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    margin-top: 55px;

    img {
      padding: 0;
      width: 70%;
    }
  }

  #first-section {
    padding: 40px 150px;
    margin-right: auto;
    margin-left: auto;

    @include for-phone-only {
      padding: 30px !important;
    }
    @include for-xx-small-desktop-up {
      width: 1000px;
      padding: 40px 0;
    }
    @include for-x-small-desktop-up {
      width: 1200px;
      padding: 40px 0;
    }
    @include for-small-desktop-up {
      width: 1281px;
      padding: 40px 0;
    }
    @include for-large-desktop-up {
      width: 1800px;
      padding: 40px 180px;
    }
    @include for-x-large-desktop-up {
      width: 2200px;
      padding: 40px 280px;
    }
    @include for-xx-large-desktop-up {
      width: 2800px;
      padding: 40px 280px;
    }

    .section-1 {
      padding: 0;
      display: flex;
      padding-top: 60px;

      .slide-text {
        font-family: $myriad+$semibold;
        //font-size: 22px;
        width: 66%;
        color: white;
        line-height: 1.5;
        padding: 0 22px 0 0px;
        text-align: justify;

        @include for-phone-only {
          width: 100% !important;
        }
        @include for-xx-small-desktop-up {
          //font-size: 17px;
          //line-height: 1;
          //text-align: justify;
        }

        .title {
          span {
            font-size: 30px;
            transform: translateY(75px);
            font-family: $myriad+$semibold;
            font-weight: bolder;

            @include for-xx-large-desktop-up {
              font-size: 50px;
            }
          }
        }

        .desc {
          span {
            font-size: 17px;
            transform: translateY(75px);
            font-family: $myriad+$regular;
            font-weight: 100;
            word-spacing: 5px;

            @include for-xx-large-desktop-up {
              font-size: 40px;
            }
          }
        }

        //margin-top: 50px;
      }
    }


    .slide-images {
      padding: 0;
      transform: translateY(-50px);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @include safari-only {
        transform: translateY(185%);


        @include responsiveProperty(transform, translateY(275%), translateY(175%), translateY(165%), translateY(155%), translateY(175%), translateY(135%), translateY(125%), translateY(115%), translateY(105%));
        @include responsiveProperty(height, 265px, 100px, 200px, 400px, 300px, 350px, 350px, 350px, 350px);
        @include responsiveProperty(margin-bottom, 22%, 22%, 22%, 22%, 0%, 0%, 0%, 0%, 0%);
        //margin-bottom: 22%;
      }
      @include for-phone-only {
        //transform: scale(1.2) !important;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      div[class*='image-'] {
        padding: 0;
        display: inline-flex;
        width: 23%;
        z-index: 2222;
        position: relative;
        margin: 0 -20px;

        &:nth-child(1) {
          z-index: 99;
          @include for-phone-only {
            margin-top: 33%;
          }
        }

        &:nth-child(2) {
          z-index: 88;
        }

        &:nth-child(3) {
          z-index: 99;
        }

        &:nth-child(4) {
          z-index: 88;
        }

        &:nth-child(5) {
          z-index: 77;
        }


        img {
          width: 100%;
          z-index: 0;
          object-fit: contain;
        }

      }
    }
  }


  #second-section {
    padding: 40px 150px;
    margin-top: -17%;

    @include for-xx-small-desktop-up {
      width: 1000px;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 0;
      margin-top: -14%;

      &.language-tr {
        margin-top: -9%;
      }
    }
    @include for-x-small-desktop-up {
      width: 1200px;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 0;
      margin-top: -200px;
    }
    @include for-small-desktop-up {
      width: 1281px;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 0;
      margin-top: -200px;
    }

    @include for-large-desktop-up {
      width: 1800px;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 180px;
      margin-top: -200px;
    }

    @include for-x-large-desktop-up {
      width: 2200px;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 280px;
      margin-top: -300px;
    }

    @include for-xx-large-desktop-up {
      width: 2800px;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 280px;
      margin-top: -300px;
    }


    @include for-phone-only {
      padding: 32px !important;
    }

    .section-item {
      padding: 0px 21px 0px 0px;

      @include for-phone-only {
        margin-top: 40px !important;
      }

      > div {
        box-shadow: 6px 7px 16px -5px #000000;
        padding: 50px 50px;
        width: 100%;
        border-radius: 5px;
      }

      .read-btn {
        color: #DF061F;
        width: 100px;
        background: rgb(223, 6, 31);
        background: -moz-radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 3%, rgba(187, 2, 15, 1) 26%, rgba(154, 0, 0, 1) 85%, rgba(154, 0, 0, 1) 100%);
        background: -webkit-radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 3%, rgba(187, 2, 15, 1) 26%, rgba(154, 0, 0, 1) 85%, rgba(154, 0, 0, 1) 100%);
        background: radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 3%, rgba(187, 2, 15, 1) 26%, rgba(154, 0, 0, 1) 85%, rgba(154, 0, 0, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df061f", endColorstr="#9a0000", GradientType=1);
        box-shadow: 4px 6px 11px -5px #000000;
        text-align: center;
        padding: 3px 0px;
        border-radius: 5px;
        margin-top: 12px;

        a {
          padding: 0;
          color: #fff;
        }
      }

      #production-line-section {
        background: rgb(223, 6, 31);
        background: -moz-radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 3%, rgba(187, 2, 15, 1) 26%, rgba(154, 0, 0, 1) 85%, rgba(154, 0, 0, 1) 100%);
        background: -webkit-radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 3%, rgba(187, 2, 15, 1) 26%, rgba(154, 0, 0, 1) 85%, rgba(154, 0, 0, 1) 100%);
        background: radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 3%, rgba(187, 2, 15, 1) 26%, rgba(154, 0, 0, 1) 85%, rgba(154, 0, 0, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df061f", endColorstr="#9a0000", GradientType=1);


        h1 {
          padding: 0;
          color: #fff;
          font-family: $myriad+$semibold;

          @include for-phone-only {
            font-size: 20px !important;
          }
        }


        span {
          padding: 0;
          color: #fff;
          font-size: 18px;
          @include for-phone-only {
            font-size: 10px !important;
          }
          line-height: 2;
          font-weight: 100;
          font-family: $myriad+$regular;
          @include for-first-large-desktop-up {
            font-size: 16px;
          }
        }


      }


      #certificates-section {
        background-color: #fff;
        padding: 40px 50px;

        h1 {
          padding: 0;
          color: #DF061F;
          font-family: $myriad+$semibold;

          @include for-phone-only {
            font-size: 20px !important;
          }
        }


        span {
          padding: 0;


          font-size: 18px;

          &.language-ar, &.language-ku {
            font-size: 16px;
          }

          @include for-phone-only {
            font-size: 10px !important;
          }
          color: #DF061F;
          line-height: 2;
          font-weight: 100;
          font-family: $myriad+$regular;
        }


      }
    }
  }


  #third-section {
    padding: 0;

    .section-item {
      padding: 0;

      .video-player {
        padding: 0;
        overflow: hidden;
        //height: 500px;
        display: flex;
        align-items: center;
      }
    }


  }

  #forth-section {
    padding: 0;
    width: 100%;
    @include for-xx-large-desktop-up {
      padding: 40px 280px;
    }

    .section-item {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-top: 50px;
        flex-direction: column;
      }


      h4 {
        text-align: center;
        color: #fff;


        @include for-phone-only {
          font-size: 70px !important;
        }
        font-size: 99px;
        font-family: $myriad+$semibold;
        margin-top: -22px;
        margin-bottom: 0;
      }

      span {
        text-align: center;
        color: #fff;

        @include for-phone-only {
          font-size: 32px !important;
        }
        font-size: 45px;

        font-family: $myriad+$condensed;
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        @include for-tablet-portrait-up {
          display: block;
        }

        li {
          width: 20% !important;
          height: auto;
          margin: 45px 0px;
          display: inline-flex;
          justify-content: center;
          align-items: center;


          @include for-phone-only {
            margin: 45px -17px;
          }
          @include for-tablet-portrait-up {
            width: 25%;
          }

        }
      }

    }

    .swiper-container {
      padding: 30px 0;
    }

    .slider-image-item {
      padding: 0;
      margin: 0 20px;
      width: 200px !important;
      height: 200px !important;
      //max-width: 200px;
      //max-height: 200px;
      overflow: hidden;
      border-radius: 15px;
      cursor: pointer;
      box-shadow: 4px 6px 11px -5px #000000;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        padding: 0;
        margin-top: 11px;
        width: 116%;
        height: 137%;
        object-fit: cover;


      }
    }

    .swiper-pagination {
      display: none;
    }
  }
}
