$productInfoBg: radial-gradient(circle, #002D74 0%, #110B79 100%);
$pageBackground: radial-gradient(circle, #002D74 0%, #110B79 100%);
$footerBackground: radial-gradient(circle, #002D74 0%,  #002D74 100%);
$solidColor:#002D74;
$menuBarFontColor:#ffff;
$menuBarDropdownFontColor:#ffffff;
$menuBarDropdownBackground:#002D74;


@import "./src/containers/themes/generalTheme";
@include getTheme("natural_water",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);


#app.app-natural_water {

  .slide-images {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;

    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      width: 13%;
      z-index: 2222;
      margin: 0 -82px;


      &:nth-child(2){
        //width: 15% !important;
        z-index: 9999;

        img {
          width: 100%;
          z-index:9999;
        }
      }
      img {
        width: 100%;
        z-index: 0;
      }

    }
  }
}
