$productInfoBg: radial-gradient(circle, #0094a8 0%, #00778B 100%);
$pageBackground: radial-gradient(circle, #0094a8 0%, #00778B 100%);
$footerBackground: radial-gradient(circle, rgba(249,0,19,1) 0%, rgba(249,0,19,1) 100%);
$solidColor:#00778B;
$menuBarFontColor:#ffff;
$menuBarDropdownFontColor:#00778B;
$menuBarDropdownBackground:#fff;


@import "./src/containers/themes/generalTheme";
@include getTheme("rich",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);

$min-width-desktop-xxLarge: 2560px;

#app.app-rich {

  .slide-images {
    padding: 0;
    position: absolute;
    top: 10%;
    width: 100%;
    align-items: flex-start !important;

    &.browser_safari{
      @include responsiveProperty(
                      transform,
                      scale(2.5) translateY(30%), //for-phone-only
                      scale(4) translateY(27%), //for-tablet-portrait-up
                      scale(3) translateY(7%), //for-tablet-landscape-up
                      scale(2) translateY(-4%), //for-xx-small-desktop-up
                      scale(2) translateY(0%), //for-x-small-desktop-up
                      scale(1.8) translateY(0%), //for-small-desktop-up
                      scale(1.3) translateY(0%), //for-large-desktop-up
                      scale(1.2) translateY(-10%), //for-x-large-desktop-up
                      scale(1.2) translateY(-10%), //for-xx-large-desktop-up
      );
    }




    div[class*='image-'] {
      padding: 0;
      display: flex;
      justify-content: center;

      margin: 0 -48px;
      width: 13%;
      height: 100%;
      z-index: 2222;

      &:first-child {
        //width: 13% !important;
        //z-index: 1000 !important;
        margin: 0 -3% !important;
      }

      &:nth-child(2) {
        //width: 15% !important;
        //z-index: 2000 !important;
        margin: 0 -2% !important;
      }
      &:nth-child(3) {
        //width: 13% !important;
        //z-index: 1000 !important;
        margin: 0 -3% !important;
      }

      &:last-child {
        //width: 12% !important;
        //z-index: 999 !important;
        margin: 0 -9px !important;

        &.browser_safari{
          @include responsiveProperty(
                          margin,
                          50px -9px !important, //for-phone-only
                          23px -9px !important, //for-tablet-portrait-up
                          23px -9px !important, //for-tablet-landscape-up
                          50px -9px !important, //for-xx-small-desktop-up
                          50px -9px !important, //for-x-small-desktop-up
                          50px -9px !important, //for-small-desktop-up
                          50px -9px !important, //for-large-desktop-up
                          50px -9px !important, //for-x-large-desktop-up
                          50px -9px !important, //for-xx-large-desktop-up
          );
        }
      }
    }



    img {
      width: 100%;
      z-index: 9999;
    }
  }
}
