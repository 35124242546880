$productInfoBg: radial-gradient(circle, rgba(0, 45, 116, 0.8) 0%, #110b79 100%);
$pageBackground: radial-gradient(circle, rgba(0, 45, 116, 0.8) 0%, #110b79 100%);
$footerBackground: radial-gradient(circle, rgba(0, 45, 116, 0.8) 0%, #110b79 100%);
$solidColor:#0B0B6D;
$menuBarFontColor:#ffff;
$menuBarDropdownFontColor:#ffffff;
$menuBarDropdownBackground:#0B0B6D;

@import "./src/containers/themes/generalTheme";
@include getTheme("valencia",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);

#app.app-valencia {

  .slide-images {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    align-items: flex-end !important;

    &.browser_safari{
      margin-top: -20%;
    }

    div[class*='image-'] {
      padding: 0;
      display: inline-flex;
      width: 20%;

      position: relative;
      margin: 0 -5%;

      &:nth-child(1){
        z-index: 88 !important;
      }
      &:nth-child(2){
        z-index: 99 !important;
        //margin-right: -3%;
      }
      &:nth-child(3){
        z-index: 99 !important;
        //margin-left: -3%;
      }
      &:nth-child(4){
        z-index: 88 !important;
      }
      &:nth-child(5){
        z-index: 100 !important;
        width: 20%;
      }
      img {
        width: 100%;
        z-index: 0;
      }

    }
  }

}
