@import "src/assets/css/responsive";

#privacy-policy {
  margin-top: 95px;


  &.page-contents-rtl {
    text-align: right;
  }

  #title-section {
    //padding: 0 170px;
    margin-bottom: 55px;

    @include responsiveProperty(margin-top, 16%, 16%, 16%, 24%, 21%, 19%, 19%, 16%, -11%);

    h1 {
      padding: 0;
      text-align: inherit;
      font-weight: bolder;
      color: #fff;
      font-size: 60px;
    }
  }

  .details-section.row {
    //padding: 0 140px;
    margin-bottom: 100px;


    h1 {
      padding: 0;
      margin: 0;
      text-align: inherit;
      color: #fff;
      font-size: 30px;
      font-weight: bolder;
    }

    .section {
      padding: 0;
      margin-top: 20px;

      span {
        text-align: justify;
        display: block;
        padding: 0 40px;
        color: white;
        font-size: 20px;
      }
    }
  }

}
