@import url("https://use.typekit.net/cyr8qpt.css");

$myriad: 'Myriad Pro ';
$semibold: 'Semibold';
$condensed: 'Condensed';
$regular: 'Regular';


@import "src/assets/css/responsive";

$fontFamily: 'Lato', sans-serif;


* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

body {
  font-family: $fontFamily;
}


header.navBar.navBarLTR {

  > ul.main-navBar {
    padding: 0 100px 0 0;

    @include for-phone-only{
      padding: 0;
    }

    > li {
      //font-size: 20px;
      text-transform: uppercase;
      font-family: $myriad+$regular;
      font-weight: 300;
      z-index: 9999;
      cursor: pointer;



      > .menu-dropdown-list {


        @include for-phone-only{
          overflow-y: auto;
        }
        > ul {
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;



          @include for-phone-only{
            display: block;
            position: relative;
            width: 60%;
            margin: auto;
          }

          > li {
            padding: 10px 0;
            border-radius: 4px;
            width: 47%;
            font-size: 13px;
            text-align: center;
            font-family: $myriad+$semibold;
            cursor: pointer;

            @include for-phone-only{
              width: 100%;
              margin: 12px 0;
            }


          }
        }
      }

    }
  }


  .checkbtn {
    font-size: 30px;
    color: white;
    float: right;
    line-height: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: none;

    @include for-phone-only{
      display: block;
      padding: 21px;
    }
  }

  #check {
    display: none;


  }



}

header.navBar.navBarRTL {

  > ul.main-navBar {
    padding: 0 0 0 100px;

    @include for-phone-only{
      padding: 0;
    }
    > li {
      //font-size: 20px;
      text-transform: uppercase;
      font-family: $myriad+$regular;
      font-weight: 300;
      z-index: 9999;
      cursor: pointer;


      > .menu-dropdown-list {


        @include for-phone-only{
          overflow-y: auto;
        }
        > ul {
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          @include for-phone-only{
            display: block;
            position: relative;
            width: 60%;
            margin: auto;
          }
          > li {
            padding: 10px 0;
            border-radius: 4px;
            width: 47%;
            font-size: 13px;
            text-align: center;
            font-family: $myriad+$semibold;
            cursor: pointer;

            @include for-phone-only{
              width: 100%;
              margin: 12px 0;
            }


          }
        }
      }

    }
  }


  .checkbtn {
    font-size: 30px;
    color: white;
    float: left;
    line-height: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: none;

    @include for-phone-only{
      display: block;
      padding: 21px;
    }
  }

  #check {
    display: none;


  }



}

header.navBar {
  width: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 20%;
  z-index: 9997;



  @include for-xx-large-desktop-up{
    padding: 100px;
    height: auto;
  }

  @include for-phone-only{
    height: unset;
    padding: 0;
    position: fixed;

    display: block;
    z-index: 9999;
  }

  label.logo {
    padding: 0 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 20%;
    height: 100%;
    z-index: 9999;


    @include responsiveProperty(width,147px,200px,200px,200px,21%,20%,14%,13%,20%);
    @include for-large-desktop-up{
      padding: 0 30px;
    }

    @include for-phone-only{
      padding: 12px 25px;
      margin: 0;
    }
    img {
      width: 100%;

      @include for-large-desktop-up{
        width: 100%;
      }
    }


  }

  > ul.main-navBar {
    padding: 0 100px 0 0;
    display: flex;
    width: 80%;
    z-index: 9999;
    align-items: center;
    justify-content: flex-end;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &.product_info_open_LTR{
      transform: translateX(-170px);
    }
    &.product_info_open_RTL{
      transform: translateX(170px);
    }

    @include for-x-large-desktop-up{

      padding: 0 70px 0 70px;
    }
    @include for-phone-only{
      position: fixed;
      width: 100%;
      height: 100%;
      //background: #2c3e50;
      top: -100%;
      transform: scale(0);
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 0;
      transition: all .5s;
    }

    > li {

      @include responsiveProperty(font-size,16px,16px,16px,16px,20px,20px,30px,38px,38px);


      //font-size: 20px;
      text-transform: uppercase;
      font-family: $myriad+$regular;
      font-weight: 300;
      margin: 0 20px;
      z-index: 9999;
      cursor: pointer;

      &:hover .menu-dropdown-list {
        height: 60*7+px;
        @include for-phone-only{
          height: 65*7+px;
        }
      }



      > a {
        color: white;
        text-decoration: none;



        @include for-phone-only{
          font-size: 20px;

          &:hover, &.active {
            background: none;
            color: #0082e6;
          }
        }
      }
      @include for-phone-only{
        margin: 20px 0;
        display: block;
        line-height: 30px;
        width: 100%;
      }

      > .menu-dropdown-list {
        padding: 0;
        position: absolute;
        list-style: none;
        width: 280%;
        border-radius: 5px;
        overflow: hidden;
        height: 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        z-index: 9999;

        @include responsiveProperty(width,100%,490%,490%,490%,490%,490%,280%,280%,280%);
        @include for-phone-only{
          position: relative;
        }

        > ul {
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;



          @include for-phone-only{
            display: block;
            position: relative;
            width: 35%;
            margin: auto;
          }

          > li {
            margin: 1px 2px;
            border-radius: 4px;
            width: 47%;
            font-size: 13px;
            text-align: center;
            cursor: pointer;


            @include for-phone-only{
              width: 100%;
              margin: 12px 0;
            }

            &:last-child{
              width: calc(94% + 4px);
              margin: 0 2px 0 2px;
            }
          }
        }
      }


    }
  }


  .checkbtn {
    font-size: 30px;
    color: white;
    line-height: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: none;

    @include for-phone-only{
      display: block;
      padding: 21px;
    }
  }

  #check {
    display: none;


  }

  @include for-phone-only{

    #check:checked ~ ul {
      top: 75px;
      transform: scale(1);
    }
    #check:checked ~ div.socialMedia {
      position: fixed;
    }
  }


}
