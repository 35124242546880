$productInfoBg: radial-gradient(#ff5a58 0%, #fc151d 100%);
$pageBackground: radial-gradient(circle, rgb(223, 6, 31) 0%, rgb(223, 6, 31) 3%, rgb(187, 2, 15) 26%, rgb(154, 0, 0) 85%, rgb(154, 0, 0) 100%);
$footerBackground: radial-gradient(circle, rgb(223, 6, 31) 0%, rgb(223, 6, 31) 3%, rgb(187, 2, 15) 26%, rgb(154, 0, 0) 85%, rgb(154, 0, 0) 100%);
$solidColor:#DF061F;
$menuBarFontColor:#fff;
$menuBarDropdownFontColor:#ffffff;
$menuBarDropdownBackground:#DF061F;


@import "./src/containers/themes/generalTheme";


@include getTheme("default",$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg);
