@import url("https://use.typekit.net/cyr8qpt.css");
$fontFamily: 'Lato', sans-serif;


$min-width-desktop-xxxLarge: 3072px;

$min-width-desktop-1: 1090px;
$min-width-desktop-2: 1440px;
$min-width-mobile-1: 858px;


@import "src/assets/css/responsive";


body{
  overflow-x: hidden;
}
#app {

  &:not(.app-default) {
    height: 100vh;
    overflow: hidden;
    position: relative;


    @include for-normal-height{
      height:800px;
      overflow: hidden;
    }

    @include for-big-height{
      height:100vh;

    }



    #footer {
      /*
      @include for-xx-small-desktop-up{
        width: 1000px;
      }
      @include for-x-small-desktop-up{
        width: 1200px;
      }
      @include for-small-desktop-up{
        width: 1281px;
      }
      @include for-large-desktop-up{
        width: 1800px;
      }
      @include for-x-large-desktop-up{
        width: 2300px;
      }
      */

      @include for-xx-large-desktop-up{
        transform: scale(1.5) translate(7%, 25%);
      }

      @include for-x-large-height{
        position: absolute;
        bottom: 0;

      }

    }

    #page-container {
      overflow: hidden;
      height: 100%;
      //overflow-x: hidden;

      @include for-phone-only{
        overflow: auto;
        overflow-x: hidden;
        @include safari-only{
          overflow: hidden;
        }

      }
    }
  }
  overflow-x: hidden;
  //background-color: #bc0b18;
  //background-image: url("../../../assets/images/home/page.png");
  background-size: 100% auto;
  //height: 500vh;


  .image_background {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    overflow: hidden;

    @include responsiveProperty(height,300px,500px,600px,600px,600px,600px,600px,600px,600px);

    &.image_background_karwnchi {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 60%;

      }
    }

    img {
      width: 100%;

    }

  }


  #page-container {

    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;

    overflow: hidden;

  }
}
