@mixin for-phone-only {
  @media (min-width: 320px) and (max-width: 767px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) { @content; }
}
@mixin for-xx-small-desktop-up {
  @media (min-width: 1025px) and (max-width: 1599px) { @content; }
}
@mixin for-x-small-desktop-up {
  @media (min-width: 1281px) { @content; }
}
@mixin for-small-desktop-up {
  @media (min-width: 1500px) { @content; }
}

@mixin for-large-desktop-up {
  @media (min-width: 1800px) { @content; }
}
@mixin for-x-large-desktop-up {
  @media (min-width: 2200px) { @content; }
}
@mixin for-xx-large-desktop-up {
  @media (min-width: 2800px) { @content; }
}
@mixin for-xxx-large-desktop-up {
  @media (min-width: 3200px) { @content; }
}


@mixin safari-only{
  &.browser_safari{
    @content;
  }
  &.browser_ios{
    @content;
  }
}

@mixin responsiveProperty(
  $property,
  $for-phone-only,
  $for-tablet-portrait-up,
  $for-tablet-landscape-up,
  $for-xx-small-desktop-up,
  $for-x-small-desktop-up,
  $for-small-desktop-up,
  $for-large-desktop-up,
  $for-x-large-desktop-up,
  $for-xx-large-desktop-up
){
 @include for-phone-only{
   #{$property}:$for-phone-only;
 }
  @include for-tablet-portrait-up{
    #{$property}:$for-tablet-portrait-up;
  }
  @include for-tablet-landscape-up{
    #{$property}:$for-tablet-landscape-up;
  }
  @include for-xx-small-desktop-up{
    #{$property}:$for-xx-small-desktop-up;
  }
  @include for-x-small-desktop-up{
    #{$property}:$for-x-small-desktop-up;
  }
  @include for-small-desktop-up{
    #{$property}:$for-small-desktop-up;
  }
  @include for-large-desktop-up{
    #{$property}:$for-large-desktop-up;
  }
  @include for-x-large-desktop-up{
    #{$property}:$for-x-large-desktop-up;
  }
  @include for-xx-large-desktop-up{
    #{$property}:$for-xx-large-desktop-up;
  }


}



@mixin for-desktop-up {
  @media (min-width: 1025px) and (max-width: 1599px) { @content; }
}
@mixin for-first-large-desktop-up {
  @media (min-width: 1281px) { @content; }
}



@mixin for-small-height {
  @media (max-height: 400px) {
    @content;
  }
}
@mixin for-normal-height {
  @media (max-height: 800px) { @content; }
}
@mixin for-big-height {
  @media (min-height: 801px) { @content; }
}
@mixin for-x-large-height {
  @media (min-height: 1100px) { @content; }
}
@mixin for-xx-large-height {
  @media (min-height: 1500px) { @content; }
}


