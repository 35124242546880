@import "../../assets/css/responsive";



@mixin background($value){
  background-image: -moz-#{$value};
  background-image: -webkit-#{$value};
  background-image: #{$value};
}
@mixin getTheme($id,$pageBackground,$footerBackground,$solidColor,$menuBarFontColor,$menuBarDropdownFontColor,$menuBarDropdownBackground,$productInfoBg,$products_color:white) {
  $min-width-desktop-1: 1090px;
  $min-width-desktop-2: 1440px;


  #app.app-#{$id} {


    /* width */
    ::-webkit-scrollbar {
      width: 0;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $solidColor;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }


    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: $solidColor;

    @include background($pageBackground);
    //background-image: url("../../assets/images/home/page.png");

    .navBar {

      ul.main-navBar {

        color: $menuBarFontColor;

        @include for-phone-only {
          @include background($pageBackground);
        }

        li {

          .menu-dropdown-list {

            ul {

              li {
                border-color: #fff;
                background-color: $menuBarDropdownBackground;

                color: $menuBarDropdownFontColor

              }
            }
          }

          a {
            color: $menuBarFontColor;
          }

          #language-options {
            .language-label {
              border-color: $menuBarFontColor;
            }

            .options-list-container {
              background-color: transparent;
              border-color: $solidColor;
              color: $solidColor;

              ul {
                border-color: $solidColor;

                li {
                  background-color: #fff;
                  border-color: $solidColor;

                  &:hover {
                    background-color: $solidColor;
                    color: #ffffff;
                  }
                }

                li.selected {
                  background-color: $solidColor !important;
                  color: #fff;
                }

              }
            }
          }
        }


        .menu-list-container {
          background-color: #5f0001;

          ul {
            li {
              a {
                color: white;
              }


              .menu-dropdown-list {
                ul {
                  li {
                    border-color: black;
                    background-color: #cd0316;
                  }
                }
              }
            }
          }
        }
      }

      @include for-phone-only{
        @include background($pageBackground);
      }
    }

    .socialMedia{
      @include for-phone-only{
        //background-color: $solidColor;
      }
    }
    .category_products_btn {

      a {
        &:hover {
          background-color: #fff !important;
          border: 3px solid $solidColor !important;
          color: $solidColor !important;
        }

        span {

        }
      }
    }


    .contents-list{

      /* width */
      ::-webkit-scrollbar {
        width: 3px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $solidColor;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }


      ul{



        li{
          .item-btn{
            border-color: $products_color;

            a{
              color: $products_color;

              &:hover{
                color: $products_color;
              }
            }
          }
        }
      }
    }

    #footer {

      .footer-contents {
        @include background($footerBackground);

        .address-section {
          color: white;
        }

        .links-section {
          border-color: white;

          ul {
            li {
              a {
                color: white;
              }
            }
          }
        }
      }


      .footer-line {

        span {
          color: $menuBarFontColor;
        }
      }

      &.page_category{
        @include background($pageBackground);

        .footer-line {
          span {
            color: white;
          }
        }
      }
    }


    #productInfo{
     @include background($productInfoBg);

      .product-title {
        padding: 0;
        h3 {
          color: white;
        }
      }
      .product-ingredients {
        ::-webkit-scrollbar {
          width: 4px;
        }
        ul {
          /* width */

          li {
            span {
              color: white;
            }
          }
        }
      }
    }
  }



}

@mixin makeTransform($scale:1,$translateX:0,$translateY:0,$important:0) {

  @if ($important == 1) {
    -moz-transform: scale($scale) translate($translateX+px, $translateY+px) !important;
    -webkit-transform: scale($scale) translate($translateX+px, $translateY+px) !important;
    -o-transform: scale($scale) translate($translateX+px, $translateY+px) !important;
    -ms-transform: scale($scale) translate($translateX+px, $translateY+px) !important;
    transform: scale($scale) translate($translateX+px, $translateY+px) !important;
  } @else {
    -moz-transform: scale($scale) translate($translateX+px, $translateY+px);
    -webkit-transform: scale($scale) translate($translateX+px, $translateY+px);
    -o-transform: scale($scale) translate($translateX+px, $translateY+px);
    -ms-transform: scale($scale) translate($translateX+px, $translateY+px);
    transform: scale($scale) translate($translateX+px, $translateY+px);
  }

}
@mixin makeTransition($duration:0.5s,$timing:ease) {

  transition: all $duration;
  -moz-transition: all $duration;
  -webkit-transition: all $duration;
  -o-transition: all $duration;

  transition-delay: $timing;
  -moz-transition-delay: $timing;
  -webkit-transition-delay: $timing;
  -o-transition-delay: $timing;



}


@mixin makeAnimationTransform($value) {

  -moz-transform: $value;
  -webkit-transform: $value;
  -o-transform: $value;
  -ms-transform: $value;
  transform: $value;

}
